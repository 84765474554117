import { Icon } from "@iconify/react";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//component

//redux
import { useSelector, useDispatch } from "react-redux";
import { setModalScanQr } from "../../../redux/reducer/modalReducer";

export default function OptionCard({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const p_guest = localStorage.getItem("p_guest");
  const token = localStorage.getItem("token");
  const guest = localStorage.getItem("guest");
  const table = localStorage.getItem("table");
  const { open_at, close_at } = useSelector((state) => state.menu.data);
  const { card_loading } = useSelector((state) => state.menu);
  const currentTime = moment();
  const todayCloseMoment = moment(close_at, "HH:mm");
  const todayOpenMoment = moment(open_at, "HH:mm");
  const tomorrowCloseMoment = moment(close_at, "HH:mm").add(1, "days");
  const tomorrowOpenMoment = moment(open_at, "HH:mm");
  const isOpenNowToday = currentTime.isBetween(
    todayOpenMoment,
    todayCloseMoment
  );
  const isOpenNowTomorrow = currentTime.isBetween(
    tomorrowOpenMoment,
    tomorrowCloseMoment
  );
  const handleGotoMenuPage = () => {
    if ((guest || p_guest || token) && item.name !== "Delivery") {
      navigate("/menu");
    } 
  };

  return (
    <MDBContainer
      className="option-card-container"
      onClick={() => {
        if (
          todayOpenMoment.isBefore(todayCloseMoment)
            ? isOpenNowToday
            : isOpenNowTomorrow
        ) {
          handleGotoMenuPage();
        }
        else if (!card_loading && item.name === "Dine In" && !table){
            dispatch(setModalScanQr(true))
        }
      }}
    >
      <label className="_label --time">
        {card_loading && "Loading ..."}
        {!card_loading && item.name === "Dine In" && !table
          ? "Please Scan the QR"
          : !card_loading &&
            item.name === "Dine In" &&
            (todayOpenMoment.isBefore(todayCloseMoment)
              ? !isOpenNowToday
              : !isOpenNowTomorrow)
          ? moment(open_at ? open_at : "00:00", "HH:mm").format("hh:mm A") +
            " - " +
            moment(close_at ? close_at : "00:00", "HH:mm").format("hh:mm A")
          : ""}
        {!card_loading && item.name === "Delivery" && "Comming soon"}
      </label>
      <div className="img-div">
        <img
          src={item.img}
          alt="food-link"
          style={{
            filter:
              item.name === "Delivery"
                ? "grayscale(80%)"
                : item.name === "Dine In" &&
                  (todayOpenMoment.isBefore(todayCloseMoment)
                    ? !isOpenNowToday
                    : !isOpenNowTomorrow)
                ? "grayscale(80%)"
                : "",
          }}
        />
      </div>
      <label className="_label --title">{item.name}</label>
    </MDBContainer>
  );
}
