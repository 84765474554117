import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { HomeBanner } from "../../../assets/images";
//component
import useWindowDimensions from "../../../components/common/window-dimension";
//redux
import { useSelector, useDispatch } from "react-redux";
import { setModalScanQr } from "../../../redux/reducer/modalReducer";

export default function MerchantCard() {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { picture } = useSelector((state) => state.menu.data);

  const mixueImage = HomeBanner;

  return (
    <div>
      <div className="banner-div" style={{ position: "relative" }}>
        <Icon
          icon="iconamoon:scanner"
          height={34}
          color="#fff"
          style={{
            position: "absolute",
            right: "1%",
            top: "1%",
            zIndex: "9999",
          }}
          onClick={() => {
            dispatch(setModalScanQr(true));
          }}
        />
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: 997,
          }}
        ></div>
        {mixueImage ? (
          <motion.img
            // initial={{ width: "0%" }}
            // animate={{ width: "100%" }}
            // exit={{ width: "0%" }}
            // transition={{ duration: 3, delay: 10 }}
            src={picture ? picture : mixueImage}
            alt="food-link"
            style={{
              objectFit: width < 600 ? "cover" : "contain",
              position: "absolute",
              top: 0,
              zIndex: 998,
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
