import { Icon } from "@iconify/react";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component

//redux
import { useSelector, useDispatch } from "react-redux";
import { openModalLogin , openModalPurchaseHistory } from "../../../redux/reducer/modalReducer";

export default function MyOrderList() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  return (
   <div className="my-order_list-div">
    <MDBRow style={{alignItems : 'center'}}>
      <MDBCol className="col-6"><div>
        <label className="_label --title">
        My Order
        </label>
        </div></MDBCol>
      <MDBCol className="text-end" style={{fontSize : "11px" , display : "flex" , alignItems : "center" , justifyContent : "end" , color : '#adadad' , cursor : "pointer"}}
       onClick={() => {
        !token ? dispatch(openModalLogin()) : dispatch(openModalPurchaseHistory());
      }}
      >
        View historical orders<Icon icon="iconamoon:arrow-up-2-thin" height={18} rotate={1} />
      </MDBCol>
    </MDBRow>
   </div>
   );
}
