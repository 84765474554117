import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component

//redux
import { merchantCart } from "../../../redux/reducer/menuReducer";
import { useSelector, useDispatch } from "react-redux";
import { cashbackData } from "../../../redux/reducer/reportReducer";
import {
  openModalLogin,
  openModalCashBack,
} from "../../../redux/reducer/modalReducer";

//helper
import { formatNumber } from "../../../helper/return_number";

export default function MemberCard() {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.menu);
  const { cashback } = useSelector((state) => state.report);
  const token = localStorage.getItem("token");
  const p_guest = localStorage.getItem("p_guest");
  const guest = localStorage.getItem("guest");
  const table = localStorage.getItem("table");
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");

  useEffect(() => {
    if (token || p_guest) {
      dispatch(merchantCart({ device_id: deviceId, table_id: table }));
    }
    if (token) {
      dispatch(cashbackData());
    }
  }, []);

  return (
    <div className="member-card-div">
      <MDBRow style={{ alignItems: "center" }}>
        <MDBCol
          className="col-2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="member-icon-div">
            <Icon
              icon={
                token || p_guest ? "icon-park-outline:red-envelopes" : "bx:user"
              }
              color="#e26368"
              height={22}
            />
          </div>
        </MDBCol>
        <MDBCol
          className="col-8"
          onClick={() => {
            !token ? dispatch(openModalLogin()) : dispatch(openModalCashBack());
          }}
        >
          <MDBRow>
            <label className="_label --point">
              {(!token && !p_guest) && "Register Buddy"}
              {(token || p_guest) &&
                (token
                  ? "My Point " + formatNumber(cashback)
                  : "My Point ****")}
            </label>
          </MDBRow>
          <MDBRow>
            <label className="_label --details">
              {!token &&
                !p_guest &&
                "Unlock more features and benefits"}
              {(token || p_guest) && cart.username && "+6" + cart.username}
            </label>
          </MDBRow>
        </MDBCol>
        <MDBCol
          className="text-center"
          onClick={() => {
            !token ? dispatch(openModalLogin()) : dispatch(openModalCashBack());
          }}
        >
          <div>
            <Icon icon="ri:arrow-up-s-line" rotate={1} height={18} />
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
