import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";
//image
import HomeDelivery from "../../assets/images/home/home.delivery.png";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { DineIn, Delivery } from "../../assets/images";

//component
import MerchantCard from "./component/merchant.card";
import OptionCard from "./component/option.card";
import MyOrderList from "./component/my.order";
import MemberCard from "./component/member.card";
import MemberShop from "./component/member.shop";
import Login from "../Login";
import ModalCashback from "../Modal/cashback";
import ModalAccount from "../Modal/account";
import ModalPurchaseHistory from "../Modal/purchase.history";
import ModalPurchaseReceipt from "../Modal/purchase.receipt";
import ModalScanQr from "../Modal/scan.qr.";
//redux
import { useSelector, useDispatch } from "react-redux";
import { card } from "../../redux/reducer/menuReducer";
import { setLoadingPage } from "../../redux/reducer/menuReducer";
import apiServices from "../../services/api.services";
import { closeAllModal } from "../../redux/reducer/modalReducer";

export default function HomePage({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [scanLoading, setScanLoading] = useState(false);
  const token = localStorage.getItem("token");
  const p_guest = localStorage.getItem("p_guest");
  const expiredTime = Date.now();
  const guest = localStorage.getItem("guest");
  const table_id = searchParams.get("table");
  const table = localStorage.getItem("table");
  const {
    isOpenLogin,
    isOpenCashBack,
    isOpenAccount,
    isOpenPurchaseHistory,
    isOpenReceipt2,
    isOpenScanQr,
  } = useSelector((state) => state.modal);

  const optionDatas = [
    {
      name: "Dine In",
      img: DineIn,
      value: "order",
    },
    {
      name: "Pick Up",
      img: "https://cdn-icons-png.flaticon.com/512/189/189017.png",
      value: "pick_up",
    },
    {
      name: "Delivery",
      img: Delivery,
      value: "delivery",
    },
  ];

  const handleSubmit2 = async (link) => {
    if (!scanLoading) {
      const url = link ? new URL(link) : "";
      const searchParams = link ?  new URLSearchParams(url.search) : "";
      const tableValue =link ?  searchParams.get("table") : null;
      const tableId = tableValue !== null && tableValue !== undefined ? tableValue : table_id ? table_id : table
      try {
        const response = await apiServices.scanQr({
          table_id: tableId
        });
        setScanLoading(true);
        if (response) {
          if ((table_id || tableValue) && !table) {
            localStorage.setItem("guest", response.data.token);
          }
          dispatch(closeAllModal())
          localStorage.setItem("table", tableId);
          localStorage.setItem("expired", expiredTime + 7200000);
          dispatch(card(tableId));
          navigate("/");
        }
      } catch (ex) {
        setScanLoading(false);
        dispatch(closeAllModal())
        if (ex && Object.keys(ex).length > 0) {
          let errorMsg = "";
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                errorMsg = errors[item];
                if (
                  errorMsg[0] ==
                  "table_id is currently unavailable. Please scan QR code from another table, thank you."
                ) {
                }
              });
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setLoadingPage(true));
    if (table) {
      dispatch(card(table))
        .unwrap()
        .then((res) => {
          // dispatch(setLoadingPage(false));
        })
        .catch((ex) => {
          // dispatch(setLoadingPage(false));
        });
    }
    if (table_id !== null) {
      handleSubmit2();
    }
    const timer = setTimeout(() => {
      dispatch(setLoadingPage(false));
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <MerchantCard />
      <MDBContainer className="home-body-div">
        <MemberCard />
        <MDBRow className="mt-4">
          <MDBCol className="col-6">
            <OptionCard item={optionDatas[0]} />
          </MDBCol>
          <MDBCol className="col-6">
            <OptionCard item={optionDatas[2]} />
          </MDBCol>
        </MDBRow>
        <MyOrderList />
        <MemberShop />
        <div></div>
        <div
          className="text-center"
          style={{
            position: "fixed",
            bottom: "0%",
            color: "#000000",
            fontSize: "10px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            left: 0,
          }}
        >
          <p>Copyright © 2024 Food Link • Powered by MSI HUB</p>
        </div>
      </MDBContainer>
      {isOpenScanQr && <ModalScanQr scanQr={handleSubmit2}/>}
      {isOpenLogin && <Login />}
      {isOpenCashBack && <ModalCashback />}
      {isOpenAccount && <ModalAccount />}
      {isOpenPurchaseHistory && <ModalPurchaseHistory />}
      {isOpenReceipt2 && <ModalPurchaseReceipt />}
    </div>
  );
}
