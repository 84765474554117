import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { RemoveScroll } from "react-remove-scroll";
import { MDBRow, MDBBtn, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";
import { Html5Qrcode } from "html5-qrcode";
import { useEffect } from "react";

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { setLoadingPage } from "../../redux/reducer/menuReducer";

export default function ModalScanQr({ scanQr }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpenScanQr } = useSelector((state) => state.modal);

  useEffect(() => {
    let html5QrCode;

    const initializeQrScanner = () => {
      const qrReader = document.getElementById("qr-reader");

      if (qrReader !== null) {
        html5QrCode = new Html5Qrcode("qr-reader");
        html5QrCode.start(
          { facingMode: "environment" },
          { fps: 10, qrbox: 250 },
          (decodedText) => {
            const url = new URL(decodedText);
            const domain = url.origin;
            if (process.env.REACT_APP_URL === domain) {
              scanQr(decodedText);
              dispatch(closeAllModal())
              dispatch(setLoadingPage(true))
              const timer = setTimeout(() => {
                dispatch(setLoadingPage(false));
              }, 5000);
              return () => {
                clearTimeout(timer);
              };
            }
          },
          (err) => {
            // console.log('1')
            // dispatch(closeAllModal());
          }
        );
      }
    };

    const timeoutId = setTimeout(() => {
      initializeQrScanner();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      if (html5QrCode) {
        html5QrCode.stop();
      }
    };
  }, []);

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenScanQr}
        screenSize="fullscreen-xl-down"
        className="scan-qr-modal"
        content={
          <MDBModalBody>
            <ModalHeader title={"Scan Qr"} />
            <div>
              <div id="qr-reader" style={{ width: "100%" }}></div>
            </div>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
