import { Icon } from "@iconify/react";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//image
import { Shop ,CnyIcon} from "../../../assets/images";
//component
//redux
import { useSelector, useDispatch } from "react-redux";

export default function MemberShop() {
  const dispatch = useDispatch();

  return (
    <div>
  <div className="member-shop">
    <MDBRow>
<MDBCol className="col-8">
    <div style={{lineHeight : "18px"}}>
    <label className="_label --text">
    Redeem Voucher
    </label>
    <br/>
    <label  className="_label --message" >
        Only fully registered buddy
    </label>
    </div>
    <button className="view_all_btn">Claim</button>
</MDBCol>
<MDBCol>
   <div className="gift-div">
    <img alt="food-link-gift" src={Shop} />
   </div>
</MDBCol>
    </MDBRow>
  </div>
    <div className="stamp-icon-div">
    <img src={CnyIcon} alt="food-link-icon" />
    </div>
    </div>

   );
}
