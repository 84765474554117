import moment from "moment/moment";
import html2canvas from "html2canvas";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";

//redux
import { useSelector } from "react-redux";

export default function ModalReceipt() {
  const { isOpenReceipt } = useSelector((state) => state.modal);
  const { data } = useSelector((state) => state.report);

  const getImage = () => {
    var canvas = document.getElementById("receipt-table");
    html2canvas(canvas).then((canvas) => {
      const baserImage = canvas.toDataURL("image/png");
      var img = document.createElement("img");
      img.src = baserImage;
      canvas.toBlob((blob) => {
        // 创建分享数据
        var shareData = {
          files: [new File([blob], "screenshot.png", { type: "image/png" })],
        };

        // 如果 navigator.share 存在，则将分享数据作为参数调用它
        if (navigator.share) {
          navigator
            .share(shareData)
            .catch((error) => console.error("分享失败", error));
        }
        // 如果 navigator.share 不存在，则提示用户无法分享
        else {
          alert("抱歉，您的浏览器不支持此功能");
        }
      });
    });
  };

  const paymentStatus = {
    completed: "Completed",
    Fail: "Fail",
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenReceipt}
        screenSize="fullscreen-xl-down"
        className="receipt-modal"
        content={
          <MDBModalBody id="receipt-table">
            <ModalHeader title="Receipt" getImage={getImage} />
            {data && data.merchant_name ? (
              <MDBContainer className="receipt-container" id="receipt-table">
                 <MDBRow>
                  <label className="_label --title">
                    Order #
                    {data && data.order_no ? data.order_no : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --title">
                    {data && data.merchant_name ? data.merchant_name : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --address">
                    {data && data.merchant_address ? data.merchant_address : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">INVOICE</label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">
                    Table {data && data.table_name ? data.table_name : ""}
                  </label>
                </MDBRow>
                <MDBContainer className="payment-info">
                  <MDBRow>
                    <label className="_label --data">
                      Payment Status :{" "}
                      {data && paymentStatus[data.payment_status]}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">
                      Receipt No : {data.receipt_no ? data.receipt_no : ""}{" "}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">
                      Transaction ID : {data.reference_no ? data.reference_no : ""}{" "}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">
                      Date and time :{" "}
                      {moment(data.created_at ? data.created_at : "").format(
                        "DD MMM YYYY h:mm A"
                      )}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">Status :</label>
                  </MDBRow>
                </MDBContainer>

                <MDBRow className="list-title">
                  <MDBCol md="2" lg="2" className="col-1">
                    <label className="_label --qty">Qty</label>
                  </MDBCol>
                  <MDBCol
                    md="8"
                    lg="8"
                    className="col-8 justify-content-start d-flex"
                  >
                    <label className="_label --qty">Item</label>
                  </MDBCol>
                  <MDBCol md="" lg="" className="text-end">
                    <label className="_label --rm">RM</label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">*** {data.method} ***</label>
                </MDBRow>
                <MDBRow>
                  {data && data.user_order
                    ? data.user_order.map((item, index) => {
                        return (
                          <MDBContainer key={index} className="order-listing">
                            <MDBRow>
                              <MDBCol md="2" lg="2" className="col-1 food-col">
                                <label className="_label --detail">
                                  {item.quantity}
                                </label>
                              </MDBCol>
                              <MDBCol md="" lg="6" className="col-9 food-name">
                                <label className="_label --food">
                                  {item.menu_name}
                                </label>
                                {item.details
                                  ? item.details.map((item, index) => {
                                      return (
                                        <MDBRow key={index}>
                                          <label className="_label --detail">
                                            {item.name}
                                          </label>
                                        </MDBRow>
                                      );
                                    })
                                  : ""}
                              </MDBCol>

                              <MDBCol md="" lg="" className="col-2 food-col">
                                <label className="_label --price">
                                  {parseFloat(
                         item.total_price ? item.total_price : 0
                        ).toFixed(2)}
                                </label>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        );
                      })
                    : ""}
                </MDBRow>
                <MDBContainer className="receipt-detail">
                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">Subtotal</label>
                    </MDBCol>
                    <MDBCol className="p-0">
                      <label className="_label --data2">
                        {parseFloat(
                          data && data.subtotal ? data.subtotal : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">
                        Service Charge (
                        {data && data.service_charge_percent
                          ? parseFloat(data.service_charge_percent).toFixed(0)
                          : 0}
                        %)
                      </label>
                    </MDBCol>
                    <MDBCol className="p-0">
                      <label className="_label --data2">
                        {parseFloat(
                          data && data.service_charge ? data.service_charge : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">
                        {data && data.sales_service_tax == 0
                          ? " SST (0%)"
                          : "SST (10%)"}
                      </label>
                    </MDBCol>
                    <MDBCol className="p-0">
                      <label className="_label --data2">
                        {parseFloat(
                          data && data.sales_service_tax
                            ? data.sales_service_tax
                            : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  {/* <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">Voucher</label>
                    </MDBCol>
                    <MDBCol className="p-0">
                      <label className="_label --data2">
                        {parseFloat(
                          data.voucher_deduction ? data.voucher_deduction : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow> */}
                  <MDBRow className="total-paid">
                    <MDBCol className="p-0">
                      <label className="_label --data p-0">Total Paid:</label>
                    </MDBCol>
                    <MDBCol className="p-0 text-end">
                      <label className="_label --data2 p-0">
                        {data ? parseFloat(data.total_price).toFixed(2) : ""}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --table">
                      Thank You, Please Come Again.
                    </label>
                  </MDBRow>
                </MDBContainer>
              </MDBContainer>
            ) : (
              ""
            )}
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
