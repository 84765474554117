import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenLogin: false,
  isOpenCart: false,
  isOpenAlcoholic: false,
  isOpenCart2 : false,
  isOpenAlcoholic2: false,
  isOpenBank : false,
  isOpenReceipt : false,
  isOpenReceipt2 : false,
  isOpenReceipt3 : false,
  isOpenPurchaseHistory : false,
  isOpenClamHistory : false,
  isOpenCashBack : false,
  isOpenAccount : false,
  isOpenChangePassword : false,
  isOpenChangePhone : false,
  isOpenInformation : false,
  isOpenScanQr : false,
  isOpenRemove : false,
  isOpenPayFail : false,
  isOpenEdit : false,
  isOpenDenied : false,
  isOpenPeregister : false,
  isOpenUpdateProfile : false,
  isOpenRedeem : false,
  isOpenCongratulations : false,
  isOpenScanQr : false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModalLogin: (state, action) => {
      state.isOpenLogin = true;
    },
    openModalCart: (state, action) => {
        state.isOpenCart = true;
      },
      openModalAlcoholic: (state, action) => {
        state.isOpenAlcoholic = true;
      },
      openModalCart2: (state, action) => {
        state.isOpenCart2 = true;
      },
      openModalAlcoholic2: (state, action) => {
        state.isOpenAlcoholic2 = true;
      },
      openModalBank: (state, action) => {
        state.isOpenBank = true;
      },
      openModalReceipt: (state, action) => {
        state.isOpenReceipt = true;
      },
      openModalReceipt2: (state, action) => {
        state.isOpenReceipt2 = true;
      },
      openModalReceipt3: (state, action) => {
        state.isOpenReceipt3 = true;
      },
      openModalPurchaseHistory: (state, action) => {
        state.isOpenPurchaseHistory = true;
      },
      openModalClaimHistory: (state, action) => {
        state.isOpenClamHistory = true;
      },
      openModalCashBack: (state, action) => {
        state.isOpenCashBack = true;
      },
      openModalChangePhone: (state, action) => {
        state.isOpenChangePhone = true;
      },
      openModalAccount: (state, action) => {
        state.isOpenAccount = true;
      },
      openModalChangePassword: (state, action) => {
        state.isOpenChangePassword = true;
      },
      openModalInformation: (state, action) => {
        state.isOpenInformation = true;
      },
      openModalScanQr: (state, action) => {
        state.isOpenScanQr = true;
      },
      openModalRemove: (state, action) => {
        state.isOpenRemove = true;
      },
      openModalPayFail: (state, action) => {
        state.isOpenPayFail = true;
      },
      openModalEdit: (state, action) => {
        state.isOpenEdit = true;
      },
       openModalDenied: (state, action) => {
        state.isOpenDenied = true;
      },
      openModalPeregister: (state, action) => {
        state.isOpenPeregister = true;
      },
      openModalUpdateProfile: (state, action) => {
        state.isOpenUpdateProfile = true;
      },

      openModalRedeemOption: (state, action) => {
        state.isOpenRedeem = true;
      },

      openModalCongratulations: (state, action) => {
        state.isOpenCongratulations = true;
      },

      closeModalLogin: (state, action) => {
          state.isOpenLogin = false;
        },
        closeModalCart: (state, action) => {
          state.isOpenCart = false;
        },
        closeModalAlcoholic: (state, action) => {
          state.isOpenAlcoholic = false;
        },
        closeModalCart2: (state, action) => {
          state.isOpenCart2 = false;
        },
        closeModalAlcoholic2: (state, action) => {
          state.isOpenAlcoholic2 = false;
        },
        closeModalBank: (state, action) => {
          state.isOpenBank = false;
        },
        closeModalReceipt: (state, action) => {
          state.isOpenReceipt = false;
        },
        closeModalReceipt2: (state, action) => {
          state.isOpenReceipt2 = false;
        },
        closeModalReceipt3: (state, action) => {
          state.isOpenReceipt3 = false;
        },
        closeModalPurchaseHistory: (state, action) => {
          state.isOpenPurchaseHistory = false;
        },
        closeModalClaimHistory: (state, action) => {
          state.isOpenClamHistory = false;
        },
       closeModalCashBack: (state, action) => {
          state.isOpenCashBack = false;
        },
       closeModalChangePhone: (state, action) => {
          state.isOpenChangePhone = false;
        },
       closeModalAccount: (state, action) => {
          state.isOpenAccount = false;
        },
       closeModalChangePassword: (state, action) => {
          state.isOpenChangePassword = false;
        },
        closeModalInformation: (state, action) => {
          state.isOpenInformation = false;
        },
        closeModalScanQr: (state, action) => {
          state.isOpenScanQr = false;
        },
        closeModalRemove: (state, action) => {
          state.isOpenRemove = false;
        },
        closeModalPayFail: (state, action) => {
          state.isOpenPayFail = false;
        },
        closeModalEdit : (state , action) => {
          state.isOpenEdit = false
        },
        closeModalDenied  : (state , action) => {
          state.isOpenDenied = false
        },
        closeModalPeregister: (state, action) => {
          state.isOpenPeregister = false;
        },
        closeModalUpdateProfile: (state, action) => {
          state.isOpenUpdateProfile = false;
        },
        closeModalRedeemOption: (state, action) => {
          state.isOpenRedeem = false;
        },
        closeModalCongratulations: (state, action) => {
          state.isOpenCongratulations = false;
        },
        setModalScanQr: (state, action) => {
          state.isOpenScanQr = action.payload;
        },
 
    closeAllModal: (state, action) => {
      state.isOpenLogin = false;
      state.isOpenCart = false;
      state.isOpenAlcoholic = false;
      state.isOpenCart2 = false;
      state.isOpenAlcoholic2 = false;
      state.isOpenBank = false;
      state.isOpenReceipt = false;
      state.isOpenPurchaseHistory = false;
      state.isOpenClamHistory = false;
      state.isOpenCashBack = false;
      state.isOpenChangePhone = false;
      state.isOpenAccount = false;
      state.isOpenChangePassword = false;
      state.isOpenInformation = false;
      state.isOpenScanQr = false;
      state.isOpenRemove = false;
      state.isOpenReceipt2 = false;
      state.isOpenReceipt3 = false;
      state.isOpenPayFail = false;
      state.isOpenEdit = false;
      state.isOpenDenied = false;
      state.isOpenPeregister = false;
      state.isOpenUpdateProfile = false;
      state.isOpenRedeem = false;
      state.isOpenCongratulations = false;
      state.isOpenScanQr = false
    },
  },
});

export const {
  openModalLogin,
  closeModalLogin,
  openModalCart,
  closeModalCart,
  closeAllModal,
  openModalAlcoholic,
  closeModalAlcoholic,
  openModalCart2,
  closeModalCart2,
  openModalBank,
  closeModalBank,
  openModalReceipt,
  closeModalReceipt,
  openModalPurchaseHistory,
  closeModalPurchaseHistory,
  openModalClaimHistory,
  closeModalClaimHistory,
  openModalAccount,
  closeModalAccount,
  openModalCashBack,
  closeModalCashBack,
  openModalChangePassword,
  closeModalChangePassword,
  openModalChangePhone,
  closeModalChangePhone,
  openModalInformation,
  closeModalInformation,
  openModalScanQr,
  closeModalScanQr,
  openModalAlcoholic2,
  closeModalAlcoholic2,
  openModalRemove,
  closeModalRemove,
  openModalReceipt2,
  closeModalReceipt2,
  openModalReceipt3,
  closeModalReceipt3,
  openModalPayFail,
  closeModalPayFail,
  openModalEdit,
  closeModalEdit,
  openModalDenied,
  closeModalDenied,
  openModalPeregister,
  closeModalPeregister, 
  openModalUpdateProfile, 
  closeModalUpdateProfile,
  openModalRedeemOption,
  closeModalRedeemOption, 
  openModalCongratulations,
  closeModalCongratulations,
  setModalScanQr
} = modalSlice.actions;
export default modalSlice.reducer;
