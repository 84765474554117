import './scss/index.scss';
import { BrowserRouter, Routes, Route, useParams, useLocation } from "react-router-dom";
import { useEffect , useState } from 'react';

//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import 'animate.css/animate.compat.css';
import './scss/component/_notify.scss';

//pages
import Menu from './pages/Menu';
import ScanQR from './pages/ScanQR';
import Cart from './pages/Cart';
import Alcoholic from './pages/Alcoholic';
import MyOrder from './pages/MyOrder';
import TermsOfUse from './pages/TermOfUse';
import LoadingPage from './components/common/loading';
import HomePage from './pages/Home';

//redux
import { useDispatch, useSelector } from "react-redux"; 
import { setLoadingPage } from './redux/reducer/menuReducer';

//one signal 
// import './scss/component/_one-signal.scss';
// import { initializeOneSignal , observeNotificationChanges } from './one_signal/OneSignalSetup';
import PromptAddHomeScreen from './one_signal/PromptAddHomeScreen';

function App() {
  const dispatch = useDispatch();
  const {  loadingPage } = useSelector((state) => state.menu);
  const { bank_loading } = useSelector((state) => state.payment);
  const [initialized, setInitialized] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false) // addthis
  const [notificationPermission, setNotificationPermission] = useState("default");

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#E26368");
  }

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  // useEffect(() => {
  //       if ('standalone' in window.navigator) { 
  //         setIsStandalone(window.navigator.standalone);
  //       }
  //     }, []);
    
  //     useEffect(() => {
  //         initializeOneSignal(setInitialized, setNotificationPermission);
  //     }, []);
    
  //     useEffect(() => {
  //         observeNotificationChanges(notificationPermission);
  //     }, [notificationPermission]);


  return (
    <>
    <ReactNotifications />
    <BrowserRouter>
    {bank_loading || loadingPage && <LoadingPage /> }
    <Routes>
      {/* <Route path="/" element={<ScanQR />} /> */}
      <Route path="/menu" element={<Menu />} />
      <Route path="/alcoholic" element={<Alcoholic />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/order" element={<MyOrder />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
    </BrowserRouter>
    {/* {'standalone' in window.navigator && !isStandalone && <PromptAddHomeScreen />} */}
    </>
  );
}

export default App;
