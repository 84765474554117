import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import ProtectedRoute from "../../components/common/protected.route";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
//component
import MainHeader from "../../components/header/main-header";
import PartialRegisterModal from "../../components/modal/partial.register.modal";
import Login from "../Login";
import OrderDetail from "./component/order.detail";
import ModalPurchaseHistory from "../Modal/purchase.history";
import ModalReceipt from "../Modal/receipt";
import ModalClaimHistory from "../Modal/claim.history";
import ModalAccount from "../Modal/account";
import ModalCashback from "../Modal/cashback";
import ModalChangePassword from "../Modal/change.password";
import ModalChangePhone from "../Modal/change.phone";
import ModalInformation from "../Modal/information";
import ModalUpdateProfile from "../Modal/update.profile";
import Guest from "../Modal/guest";
import ModalPurchaseReceipt from "../Modal/purchase.receipt";
import ModalClaimReceipt from "../Modal/claim.receipt";
import PaymentFail from "../Cart/component/payment.fail.modal";
import ModalCongratulations from "../Modal/congratulations";
import ModalDenied from "../Modal/denied";
import PreRegister from "../Modal/register";

//redux
import { useDispatch, useSelector } from "react-redux";
import { orderListing } from "../../redux/reducer/cartReducer";
import {
  openModalPayFail,
  openModalReceipt2,
  openModalCongratulations,
  closeModalCongratulations,
} from "../../redux/reducer/modalReducer";
import { purchaseReceipt } from "../../redux/reducer/reportReducer";

export default function MyOrder() {
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const [receipt, setReceipt] = useSearchParams();
  const receiptNo = receipt.get("order_id");
  const [status, setStatus] = useSearchParams();
  const statusDetails = status.get("status");
  const [searchParams, setSearchParams] = useSearchParams();
  const v_token = searchParams.get("token");

  const now = Date.now();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const expired = localStorage.getItem("expired");
  const p_guest = localStorage.getItem("p_guest");
  const dispatch = useDispatch();
  const {
    isOpenLogin,
    isOpenReceipt,
    isOpenReceipt2,
    isOpenPurchaseHistory,
    isOpenClamHistory,
    isOpenChangePassword,
    isOpenAccount,
    isOpenChangePhone,
    isOpenCashBack,
    isOpenInformation,
    isOpenReceipt3,
    isOpenPayFail,
    isOpenDenied,
    isOpenCongratulations,
    isOpenUpdateProfile,
  } = useSelector((state) => state.modal);
  const [showBottom, setShowBottom] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const openReceiptFunction = () => {
    if (receiptNo !== null && statusDetails == "fail") {
      dispatch(openModalPayFail());
    } else if (receiptNo !== null && statusDetails === "success") {
      navigate("/order");
      dispatch(openModalReceipt2());
      dispatch(openModalCongratulations());
      dispatch(purchaseReceipt({ order_id: receiptNo }));
    } else if (receiptNo !== null && statusDetails !== "fail") {
      navigate("/order");
      dispatch(openModalReceipt2());
      dispatch(purchaseReceipt({ order_id: receiptNo }));
    }
  };

  useEffect(() => {
    if (now > expired) {
      navigate("/");
    }
    if (token || p_guest) {
      dispatch(orderListing({ device_id: deviceId }));
    }
    scrollToTop();
  }, []);

  useEffect(() => {
    if (token || p_guest) {
      openReceiptFunction();
    }
  }, [receiptNo]);

  useEffect(() => {
    console.log("DSd", v_token);
    if (v_token) {
      localStorage.setItem("token", v_token);
      openReceiptFunction();
    }
  }, [v_token]);

  const showModalUp = () => {
    setShowBottom(!showBottom);
  };

  return (
    <MDBContainer className="my-order-page-container">
      <ProtectedRoute />
      <MainHeader />
      <MDBContainer className="order-container">
        <MDBRow className="cart-title">
          <div className="col-12 title-col">
            <label className="_label --title">My Orders</label>&nbsp;&nbsp;
            <Icon
              icon="icon-park-outline:history-query"
              height="1.6em"
              className="icon"
            />
          </div>
        </MDBRow>
        <OrderDetail />
      </MDBContainer>
      {token || p_guest ? (
        <PartialRegisterModal
          onDismiss={showModalUp}
          showBottom={showBottom}
          show={true}
          content={
            <Guest showBottom={showBottom} setShowBottom={setShowBottom} />
          }
        />
      ) : (
        <PartialRegisterModal
          show={true}
          content={<PreRegister name="/order" />}
        />
      )}
      {isOpenLogin && <Login />}
      {isOpenPurchaseHistory && <ModalPurchaseHistory />}
      {isOpenReceipt && <ModalReceipt />}
      {isOpenClamHistory && <ModalClaimHistory />}
      {isOpenReceipt2 && <ModalPurchaseReceipt />}
      {isOpenCongratulations && <ModalCongratulations />}
      {isOpenReceipt3 && <ModalClaimReceipt />}
      {isOpenCashBack && <ModalCashback />}
      {isOpenAccount && <ModalAccount />}
      {/* {isOpenUpdateProfile && <ModalUpdateProfile />} */}
      {isOpenChangePassword && <ModalChangePassword />}
      {isOpenChangePhone && <ModalChangePhone />}
      {isOpenInformation && <ModalInformation title="Order Time" />}
      {isOpenPayFail && <PaymentFail />}
      {isOpenDenied && <ModalDenied message="expired" />}
    </MDBContainer>
  );
}
