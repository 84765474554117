import moment from "moment/moment";
import { useState, useEffect } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";
import InfiniteScroll from "react-infinite-scroll-component";

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";

import { Logo2 } from "../../assets/images"

//redux
import { useSelector, useDispatch } from "react-redux";
import { cashbackData, walletHistory } from "../../redux/reducer/reportReducer";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../components/common/window-dimension";

//helper
import { formatNumber } from "../../helper/return_number";

export default function ModalCashback() {
  const dispatch = useDispatch();
  const { isOpenCashBack } = useSelector((state) => state.modal);
  const { cashback, history, history_loading } = useSelector((state) => state.report);
  const [loader, setLoader] = useState(false);
  const [length, setLength] = useState(10);
  const [start, setStart] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { height } = useWindowDimensions()
  const getMoreData = () => {
    dispatch(walletHistory({
      size : length,
      // start : start + 10 ,
      page : start + 1
    }))
    .unwrap()
    .then((res) => {
      setStart(start + 1);
      let newData = [];
      for (var key in res.aaData) {
        newData.push({
          ...res.aaData[key],
        });
      }
      if (data.length + newData.length >= res.iTotalRecords) {
        setHasMore(false);
      }
      setData([...data, ...newData]);
    })
    .catch((ex) => {});

  }

  useEffect(() => {
    setLoader(true)
    dispatch(cashbackData());
    dispatch(walletHistory({
      size: length,
      // start: start,
      page : start
    }))
    .unwrap()
    .then((res) => {
      setData(res.aaData);
      setLoader(false)
      if (data.length !== res.iTotalRecords) {
      setHasMore(true)
      }
    })
    .catch((ex) => {});
  }, []);


  let scrollHiegth = height - 268.58

  return (
    <RemoveScroll>
      <ModalBox
        scrollable={true}
        show={isOpenCashBack}
        screenSize="fullscreen-xl-down"
        className="cashback-modal"
        content={
          <MDBModalBody style={{ background: "#F3F4F5" }}>
            <ModalHeader title="My Cashback" />
            <MDBContainer className="cashback-container">
              <MDBRow className="title-text">
                <label className="_label --title">
                  Each Stamp Value RM 0.01
                </label>
              </MDBRow>
              <MDBRow>
                <label className="_label --price">
                  {formatNumber(cashback ? cashback : 0)}
                  &nbsp;
                  <span className="_span --icon">stamp</span>
                </label>
              </MDBRow>
            </MDBContainer>
            <MDBContainer>
              <MDBRow className="listing-header">
                <MDBCol className="col-9 tag">
                  <label className="_label --listing">Cashback History</label>
                </MDBCol>
                <MDBCol className="col-3">
                  <label className="_label --text">Amount</label>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
           <InfiniteScroll 
              // style={{overflow : "unset"}}
              dataLength={data.length}
              height={`${scrollHiegth}px`}
              hasMore={hasMore}
              next={() => {
              getMoreData();
              }}
             loader={data.length > 0 && <h4 className="text-center mt-5" style={{color : "black"}}>Loading...</h4>}
      >
           <MDBContainer className="listing-container">
            {loader ? 
            <div style={{display : "flex" , marginTop : "3em" , marginBottom : "5em", alignItems: "center" , justifyContent : "center"}}>
            <Icon icon="line-md:loading-twotone-loop" color="#e26368"  height={"2.5em"}/>
          </div>
          :   !loader  && data && data.length > 0 ? data.map((item , index) => {
            const point = parseFloat(item.amount).toFixed(2);
            return (
              <div key={index}>
                {item.amount !== 0 && (
                  <MDBRow className="listing">
                    <MDBCol className="col-8">
                      <MDBRow>
                        <label className="_label --name">
                         {item.store_name !== null && item.amount > 0 ? "Collect At" :item.store_name !== null && "Redeem At"} {item.store_name !== null ? item.store_name : item.description}
                        </label>
                      </MDBRow>
                      <MDBRow>
                        <label className="_label --date">
                          at &nbsp;
                          {moment(item.created_at).format(
                            "DD MMM YYYY HH:mm"
                          )}
                        </label>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol className="text-end">
                      <label
                        className={
                          item.amount > 0
                            ? "_label --price"
                            : "_label --price2"
                        }
                      >
                        {point}
                      </label>
                    </MDBCol>
                  </MDBRow>
                )}
              </div>
            );
          })
          : 
          <div className="d-flex flex-column align-items-center empty-div" style={{ background: 'transparent' }}>
          <div style={{ height: '75%', textAlign: 'center' }}>
            <div className="loading-lottie">
            <img
              src={Logo2}
              className="logo-image"
              alt="logo-image"
              fetchpriority="high"
            />
            </div>
            <div className="loading-div d-flex justify-content-center">
              No Data Available
            </div>
          </div>
        </div>
          }
          </MDBContainer>
          </InfiniteScroll>
          </MDBModalBody>
        }
      />
     </RemoveScroll>
  );
}
