import { useState } from "react";
import moment from "moment/moment";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";
import html2canvas from "html2canvas";
import { Icon } from '@iconify/react';

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";

//redux
import { useSelector } from "react-redux";

export default function ModalClaimReceipt() {
  const [image, setImage] = useState("");
  const { isOpenReceipt3 } = useSelector((state) => state.modal);
  const { receipt , loading} = useSelector((state) => state.report);
  const getImage = () => {
    var canvas = document.getElementById("receipt-table");
    html2canvas(canvas).then((canvas) => {
      const baserImage = canvas.toDataURL("image/png");
      var img = document.createElement("img");
      img.src = baserImage;
      setImage(img);
      canvas.toBlob((blob) => {
        // 创建分享数据
        var shareData = {
          files: [new File([blob], "screenshot.png", { type: "image/png" })],
        };

        // 如果 navigator.share 存在，则将分享数据作为参数调用它
        if (navigator.share) {
          navigator
            .share(shareData)
            .catch((error) => console.error("分享失败", error));
        }
        // 如果 navigator.share 不存在，则提示用户无法分享
        else {
          alert("抱歉，您的浏览器不支持此功能");
        }
      });
    });
  };
  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenReceipt3}
        screenSize="fullscreen-xl-down"
        className="receipt-modal"
        content={
          <MDBModalBody id="receipt-table">
            <ModalHeader title="Receipt" getImage={getImage} />
            {!loading && receipt.result ? (
              <MDBContainer className="receipt-container" id="receipt-table">
                 <MDBRow>
                  <label className="_label --title">
                    Order #
                    {receipt.result && receipt.result.order_no
                      ? receipt.result.order_no
                      : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --title">
                    {receipt.result && receipt.result.merchant_name
                      ? receipt.result.merchant_name
                      : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --address">
                    {receipt.result && receipt.result.merchant_address
                      ? receipt.result.merchant_address
                      : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">INVOICE</label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">
                    {" "}
                    Table :{" "}
                    {receipt.result && receipt.result.table_name
                      ? receipt.result.table_name
                      : ""}{" "}
                  </label>
                </MDBRow>
                <MDBContainer className="payment-info">
                  <MDBRow>
                    <label className="_label --data">
                      Receipt No :{" "}
                      {receipt.result && receipt.result.receipt_no
                        ? receipt.result.receipt_no
                        : ""}{" "}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">
                      Transaction ID :{" "}
                      {receipt.result && receipt.result.reference_no
                        ? receipt.result.reference_no
                        : ""}{" "}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">
                      Date and time :{" "}
                      {moment(
                        receipt.result && receipt.result.created_at
                          ? receipt.result.created_at
                          : ""
                      ).format("DD MMM YYYY h:mm A")}
                    </label>
                  </MDBRow>
                </MDBContainer>

                <MDBRow className="list-title">
                  <MDBCol md="2" lg="2" className="col-1">
                    <label className="_label --qty">Qty</label>
                  </MDBCol>
                  <MDBCol
                    md="8"
                    lg="8"
                    className="col-8 justify-content-start d-flex"
                  >
                    <label className="_label --qty">Item</label>
                  </MDBCol>
                  <MDBCol md="" lg="" className="text-end">
                    <label className="_label --rm">RM</label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">*** Dine In ***</label>
                </MDBRow>
                <MDBRow>
                  {receipt.result && receipt.result.user_order
                    ? receipt.result.user_order.map((item, index) => {
                        return (
                          <MDBContainer key={index} className="order-listing">
                            <MDBRow>
                              <MDBCol md="2" lg="2" className="col-1 food-col">
                                <label className="_label --detail">
                                  {item.quantity}
                                </label>
                              </MDBCol>

                              <MDBCol
                                className={
                                  item.menu_code
                                    ? "col-9 food-name"
                                    : "col-9 food-name"
                                }
                              >
                                <label className="_label --food">
                                  {item.menu_name}
                                </label>
                                {item.details
                                  ? item.details.map((item, index) => {
                                      return (
                                        <MDBRow key={index}>
                                          <label className="_label --detail">
                                            {item.name}
                                          </label>
                                        </MDBRow>
                                      );
                                    })
                                  : ""}
                              </MDBCol>

                              <MDBCol className="col-1 food-col">
                                <label className="_label --price">
                                  {parseFloat(item.total_price ? item.total_price : 0).toFixed(2)}
                                </label>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        );
                      })
                    : ""}
                </MDBRow>
                <MDBContainer className="receipt-detail">
                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">Subtotal</label>
                    </MDBCol>
                    <MDBCol className="p-0 text-end">
                      <label className="_label --data2">
                        {receipt.result && receipt.result.subtotal
                          ? parseFloat(receipt.result.subtotal).toFixed(2)
                          : 0.00}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">
                        Service Charge (
                        {receipt &&
                        receipt.result &&
                        receipt.result.service_charge_percent
                          ? parseFloat(
                              receipt.result.service_charge_percent
                            ).toFixed(0)
                          : 0}
                        %)
                      </label>
                    </MDBCol>
                    <MDBCol className="p-0 text-end">
                      <label className="_label --data2">
                        {parseFloat(
                          receipt.result && receipt.result.service_charge
                            ? receipt.result.service_charge
                            : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="detail">
                    <MDBCol className="p-0 t">
                      <label className="_label --data">
                        {receipt.result &&
                        receipt.result.sales_service_tax !== 0
                          ? " SST (6%)"
                          : "SST (0%)"}
                      </label>
                    </MDBCol>
                    <MDBCol className="p-0 text-end">
                      <label className="_label --data2">
                        {parseFloat(receipt.result && receipt.result.sales_service_tax
                          ? receipt.result.sales_service_tax
                          : 0   ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  {/* <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">Voucher</label>
                    </MDBCol>
                    <MDBCol className="p-0 text-end">
                      <label className="_label --data2">
                        {parseFloat(
                          receipt.result && receipt.result.voucher_deduction
                            ? receipt.result.voucher_deduction
                            : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow> */}
                  <MDBRow className="total-paid">
                    <MDBCol className="p-0">
                      <label className="_label --data p-0">Total Paid:</label>
                    </MDBCol>
                    <MDBCol className="text-end p-0">
                      <label className="_label --data2 p-0">
                        {receipt.result && receipt.result.total_price
                          ? parseFloat(receipt.result.total_price).toFixed(2)
                          : ""}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --table mt-2">
                      Thank You, Please Come Again.
                    </label>
                  </MDBRow>
                </MDBContainer>
              </MDBContainer>
            ) : (
              ""
            )}
            {!loading && receipt.claim_history ? (
              <MDBContainer className="receipt-container">
                {receipt.claim_history && receipt.claim_history.length > 0 ? (
                  <MDBRow className="payment-text">
                    <label className="_label --table">CLAIM HISTORY</label>
                  </MDBRow>
                ) : (
                  ""
                )}
                {!loading && receipt.claim_history && receipt.claim_history.length > 0
                  ? receipt.claim_history.map((item, index) => {
                      return (
                        <MDBContainer
                          className="payment-info --payment mb-4"
                          key={index}
                        >
                          <MDBRow>
                            <MDBCol>
                              <label className="_label --data">Item Name</label>
                            </MDBCol>
                            <MDBCol>
                              <label className="_label --data2">
                                {item.item_name}
                              </label>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol>
                              <label className="_label --data">Quantity</label>
                            </MDBCol>
                            <MDBCol>
                              <label className="_label --data2">
                                {item.quantity}
                              </label>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol>
                              <label className="_label --data">Status</label>
                            </MDBCol>
                            <MDBCol>
                              <label className="_label --data2">
                                {item.status}
                              </label>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol>
                              <label className="_label --data">
                                Claim Date
                              </label>
                            </MDBCol>
                            <MDBCol>
                              <label className="_label --data2">
                                {moment(item.created_at).format(
                                  "DD MMM YYYY h:mm A"
                                )}
                              </label>
                            </MDBCol>
                          </MDBRow>
                        </MDBContainer>
                      );
                    })
                  : ""}
              </MDBContainer>
            ) : (
              <div style={{display : "flex" , marginTop : "3em" , marginBottom : "5em", alignItems: "center" , justifyContent : "center"}}>
              <Icon icon="line-md:loading-twotone-loop" color="#e26368"  height={"2.5em"}/>
              </div>
            )}
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
