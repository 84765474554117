import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";

//component
import Text from "../../../components/element/text";
import Code from "../../../components/element/code";
import Button from "../../../components/element/button";

//redux
import { useDispatch, useSelector } from "react-redux";
import { changeLogin, changeUserId } from "../../../redux/reducer/loginReducer";
import { closeModalLogin } from "../../../redux/reducer/modalReducer";
import { profile } from "../../../redux/reducer/profileReducer";
import { orderListing } from "../../../redux/reducer/cartReducer";
import { cartListing } from "../../../redux/reducer/cartReducer";
import { openModalAccount } from "../../../redux/reducer/modalReducer";
import { cashbackData } from "../../../redux/reducer/reportReducer";
import { merchantCart } from "../../../redux/reducer/menuReducer";
import { setUserno } from "../../../redux/reducer/profileReducer";
//auth
import authServices from "../../../services/auth.services";
import { Notification } from "../../../helper/react_notification";

export default function LoginForm() {
  const table = localStorage.getItem("table");
  const { cart } = useSelector((state) => state.menu);
  const [loading , setLoading]=useState(false)

  const dispatch = useDispatch();
  const state = window.location.pathname;
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const pre_number = localStorage.getItem("pre_number");

  const loginSuccess = () => {
    if (state == "/order") {
      dispatch(orderListing({ device_id: deviceId }));
    } else if (state == "/cart") {
      dispatch(cartListing({ device_id: deviceId }));
      dispatch(cashbackData());
    }
    dispatch(closeModalLogin());
    dispatch(changeLogin("Login"));
    dispatch(profile())
      .unwrap()
      .then((res) => {
        if (res.email == null) {
          dispatch(openModalAccount());
        }
      })
      .catch((ex) => {});
  };

  const userData = {
    username: pre_number ? pre_number : "",
    verification_code: "",
    table_id: table,
  };

  const loginSchema = Yup.object({
    username: Yup.string()
      .required("Required")
      .matches(
        /^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/g,
        "Invalid phone number format."
      ),
    verification_code: Yup.string().required("Required"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    setLoading(true)
    try {
      const response = await authServices.codeValidate({
        username: values.username,
        type: "phone verification",
        verification_code: values.verification_code,
        table_id: values.table_id,
      });
      if (response) {
       setLoading(false)
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("loginId", response.data.id);
        localStorage.setItem("userID", response.data.id);
        localStorage.setItem("username", values.username);
        dispatch(changeUserId(values.username));
        dispatch(setUserno(values.username))
        dispatch(merchantCart({ device_id: deviceId, table_id: table }));
        loginSuccess();
        Notification({
          title: "Login Successful!",
          message: `Welcome, ${values.username}.`,
          type: "success",
        });
      }
    } catch (ex) {
      setLoading(false)
      if (ex && ex.response.status === 422) {
      setLoading(false)
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
            setLoading(false)
          });
        }
      }
    }
  };

  return (
    <Formik
      initialValues={userData}
      validationSchema={loginSchema}
      onSubmit={(values, { resetForm, setFieldError }) => {
        handleSubmit({ values, setFieldError });
      }}
    >
      {({ errors }) => (
        <Form>
          <MDBContainer className="login-body">
            <MDBRow>
              <label className="_label --note">Let's Strat your trip </label>
            </MDBRow>
            <MDBRow>
              <label className="_label --text">
                Get an account to enjoy more & benifit on your selangor trip.
              </label>
            </MDBRow>
            <MDBRow className="tag-div">
              <label className="_label --tag">Contact</label>
            </MDBRow>
            <Text
              className="login-field"
              name="username"
              placeholder="(+60)"
              errors={errors}
              verification
              type={"tel"}
            />
            <Code className="login-field" name="verification_code" />
            {errors && errors.verification_code && (
              <div className="text-danger text-right no-padding error-message">
                {errors.verification_code}
              </div>
            )}
            <MDBRow>
              <Button
                type={"submit"}
                name="Continue"
                className="login_button"
                disabled={loading}
                loading={loading}
              />
            </MDBRow>
          </MDBContainer>
          <div className="text-center" style={{position : "fixed" , bottom : "2%" ,left : "23%" , color : "#000000" , fontSize : "10px"}}>
            Copyright © 2024 Food Link • Powered by MSI HUB 
            </div>
        </Form>
      )}
    </Formik>
  );
}
